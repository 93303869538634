<template>
  <div v-if="canAccess('cobranzas_pago_final')">
    <BlockUI :blocked="loading" :fullScreen="true" style="position:absolute;top:50%;left:50%;">
      <i v-if="loading" class="pi pi-spin pi-spinner" style="font-size: 3em;"></i>
    </BlockUI>
    <div class="p-grid" :style="{padding: '15px'}">
      <div class="p-col-12 p-md-3">
        <h4><b>MATRÍCULA:&nbsp;</b>{{data.sequential}}</h4>
      </div>
      <div class="p-col-12 p-md-5">
        <h4><b>PLAN:&nbsp;</b>{{data.plan?.name}}</h4>
      </div>
      <div class="p-col-12 p-md-4">
        <div class="p-field" style="width: 100%;text-align: right">
          <Button label="Exportar" icon="pi pi-file-pdf" class=" p-button-primary" @click="exportReport()"/>&nbsp;
          <Button label="Regresar" class=" p-button-danger p-button-outlined" @click="$router.push('/raise-money/payments-finish')" />
        </div>
      </div>
      <Fieldset legend="Pagos de matrícula" :toggleable="true" class="p-col-12">
        <div class="p-grid">
          <div class="p-col-12 p-md-12">
            <div class="card card-w-title">
            <DataTable :value="data.payments"
                       class="p-datatable-responsive-demo p-datatable-sm p-datatable-striped"
                       responsiveLayout="scroll"
                       dataKey="id" >
              <Column header="ID" sortable>
                <template #body="slotSubProps">
                  <div class="center">{{ slotSubProps.data.id }}</div>
                </template>
              </Column>
              <Column header="Tipo de pago" sortable>
                <template #body="slotSubProps">
                  <div class="center">{{ getTypePayment(slotSubProps.data.type_payment) }}</div>
                </template>
              </Column>
              <Column field="status" header="Estado" sortable></Column>
              <Column header="Medio de Pago" sortable>
                <template #body="slotSubProps">
                  <div class="center">{{  slotSubProps.data.type?.description??'-' }}</div>
                </template>
              </Column>
              <Column field="type_transaction" header="Tipo transacción" sortable>
                <template #body="slotSubProps">
                  <div class="center">{{  slotSubProps.data.type_transaction }}</div>
                </template>
              </Column>
              <Column header="Valor">
                <template #body="slotSubProps">
                  <div class="center">{{ formatMoney(slotSubProps.data.value) }}</div>
                </template>
              </Column>
              <Column header="Valor pagado">
                <template #body="slotSubProps">
                  <div class="center">{{ formatMoney(slotSubProps.data.value_paid) }}</div>
                </template>
              </Column>
              <Column header="Fecha de pago">
                <template #body="slotProps">
                  <span v-if="slotProps.data.date_payment">{{ $filters.dateFormat(slotProps.data.date_payment)}}</span>
                </template>
              </Column>
            </DataTable>
          </div>
          </div>
        </div>
      </Fieldset>
      <Fieldset legend="Gestión de Cuotas" :toggleable="true" class="p-col-12">
        <div class="p-grid">
          <div class="p-col-12 p-md-6">
            <b>EJECUTIVO DE CUENTA:&nbsp;</b>{{ data.executive?.name}}<br/>
            <b>ASIGNACIÓN:&nbsp;</b>{{ data.executive_account_date}}
          </div>
          <div class="p-col-12 p-md-6" style="text-align: right">
            <label><b style="color: darkred">REGISTRO CANCELADO EN SU TOTALIDAD</b></label>
          </div>
          <div class="p-col-12 p-md-12">
            <div class="card card-w-title">
           <DataTable :value="data.enrollment_fee"
                       class="p-datatable-responsive-demo p-datatable-sm p-datatable-striped"
                       responsiveLayout="scroll"
                      dataKey="id" >
              <Column field="fee" header="Nro. Cuota"></Column>
              <Column header="Valor a Pagar">
                <template #body="slotProps">
                  <div class="center">{{ formatMoney(slotProps.data.value) }}</div>
                </template>
              </Column>
              <Column header="Valor Pagado">
                <template #body="slotProps">
                  <div class="center">{{ formatMoney(slotProps.data.value_paid) }}</div>
                </template>
              </Column>
              <Column header="Saldo">
                <template #body="slotProps">
                  <div class="center">{{ formatMoney(calculateArrastre(slotProps.data,data)) }}</div>
                </template>
              </Column>
              <Column field="date_payment" header="Fecha de Cobro"></Column>
             <Column header="Pagos">
               <template #body="slotProps">
                 <div class="center">
                   <span :key="i" v-for="(itx,i) in slotProps.data.payments">{{itx.payment_id}},&nbsp;&nbsp;</span>
                   </div>
               </template>
             </Column>
              <Column header="Porcentaje" style="text-align: center">
                <template #body="slotProps">
                  <div  style="text-align: center;width: 100%"><b>{{((slotProps.data.value_paid*100)/slotProps.data.value).toFixed(2)}}%</b></div>
                  <ProgressBar :value="((slotProps.data.value_paid*100)/slotProps.data.value).toFixed(2)" />

                </template>
              </Column>
              <Column header="Estado" style="text-align: center">
                <template #body="slotProps">
                  <div><small style="font-weight: bold">{{ (slotProps.data.status) }}</small></div>
                </template>
              </Column>

            </DataTable>
          </div>
          </div>
        </div>
      </Fieldset>
      <Fieldset :collapsed="true" legend="Datos Matrícula" :toggleable="true" class="p-col-12">
        <div class="p-grid">
          <div class="p-col-12 p-md-3">
            <div class="p-field">
              <label >Nro. Matrícula</label>
              <input-text type="text" :value="data.sequential"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field">
              <label>Fecha de Registro</label>
              <input-text type="text" :value="data.registration"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field p-fluid">
              <label>País</label>
              <input-text type="text" :value="data.contract?.country?.name"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field   p-fluid">
              <label>Ciudad</label>
              <input-text type="text" :value="data.contract?.city?.name"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field   p-fluid">
              <label>Fecha Primer Pago</label>
              <input-text type="text" :value="data.contract?.date_first_payment"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
          </div>
          <div class="p-col-12 p-md-3">
            <div class="p-field">
              <label>Plan</label>
              <input-text type="text" :value="data.plan?.name"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field">
              <label>Valor Inscripción</label>
              <input-text type="text" :value="data.plan?.cost"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field p-fluid">
              <label>Mes</label>
              <input-text type="text" :value="data.month?.month"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field   p-fluid">
              <label>Semana</label>
              <input-text type="text" :value="data.week?.week"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field   p-fluid">
              <label>Nota Remisión</label>
              <input-text type="text" :value="data.contract?.referral_note"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
          </div>
          <div class="p-col-12 p-md-3">
            <div class="p-field   p-fluid">
              <label>Saldo</label>
              <input-text type="text" :value="data.balance"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field">
              <label>Estado</label>
              <input-text type="text" :value="data.status"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field p-fluid">
              <label>Origen de Matrícula</label>
              <input-text type="text" :value="data.registration_origin?.description"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field   p-fluid">
              <label>Organización</label>
              <input-text type="text" :value="data.organization?.description"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field   p-fluid">
              <label>Fecha Entrega Materiales</label>
              <input-text type="text" :value="data.contract?.delivery_date_materials"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
          </div>
          <div class="p-col-12 p-md-3">
            <div class="p-field p-fluid">
              <label>Asesor</label>
              <input-text type="text" :value="data.employee?.name"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field   p-fluid">
              <label>Filial</label>
              <input-text type="text" :value="data.subsidiary?.name"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field p-fluid">
              <label>El titular es beneficiario?</label>
              <input-text type="text" :value="data.is_holder_student=='y'?'SI':'NO'"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field p-fluid">
              <label>Costo Total</label>
              <input-text type="text" :value="data.plan?.total_value"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
          </div>

        </div>
      </Fieldset>

      <Fieldset :collapsed="true"  legend="Datos Personales" :toggleable="true" class="p-col-12">
        <div class="p-grid">
          <div class="p-col-12 p-md-3">
            <div class="p-field">
              <label>Cédula</label>
              <input-text id="ced" type="text" :value="data.contract?.holder?.dni"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field">
              <label for="mail">Email</label>
              <input-text id="mail" type="text" :value="data.contract?.holder?.email"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
          </div>
          <div class="p-col-12 p-md-3">
            <div class="p-field">
              <label for="name">Nombres</label>
              <input-text id="name" type="text" :value="data.contract?.holder?.name"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field">
              <label for="last_name">Apellidos</label>
              <input-text id="last_name" type="text" :value="data.contract?.holder?.last_name"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
          </div>
          <div class="p-col-12 p-md-3">
            <div class="p-field   p-fluid">
              <label for="phone">Teléfono</label>
              <input-text id="phone" type="text" :value="data.contract?.holder?.phone"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field">
              <label for="mobile_phone">Número de celular</label>
              <input-text id="mobile_phone" type="text" :value="data.contract?.holder?.mobile_phone"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
          </div>
          <div class="p-col-12 p-md-3">
            <div class="p-field p-fluid">
              <label for="civil_status">Estado civil</label>
              <input-text id="civil_status" type="text" :value="data.contract?.holder?.civil_status"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field   p-fluid">
              <label for="birth_date">Fecha de nacimiento</label>
              <input-text id="birth_date" type="text" :value="data.contract?.holder?.birth_date"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
          </div>
        </div>
      </Fieldset>

      <Fieldset :collapsed="true" legend="Datos Domicilio" :toggleable="true" class="p-col-12">
        <div class="p-grid">
          <div class="p-col-12 p-md-3">
            <div class="p-field">
              <label>Pais</label>
              <input-text type="text" :value="data.contract?.holder?.country?.name"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field">
              <label>Ciudad</label>
              <input-text id="mail" type="text" :value="data.contract?.holder?.city?.name"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
          </div>
          <div class="p-col-12 p-md-3">
            <div class="p-field">
              <label>Calle Principal</label>
              <input-text id="name" type="text" :value="data.contract?.holder?.main_street"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field">
              <label>Calle Secundaria</label>
              <input-text type="text" :value="data.contract?.holder?.second_street"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
          </div>
          <div class="p-col-12 p-md-3">
            <div class="p-field   p-fluid">
              <label >Nro. Casa</label>
              <input-text type="text" :value="data.contract?.holder?.house_number"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field">
              <label>Código Postal</label>
              <input-text type="text" :value="data.contract?.holder?.postal_code"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
          </div>
          <div class="p-col-12 p-md-3">
            <div class="p-field p-fluid">
              <label>Tipo de Vivienda</label>
              <input-text type="text" :value="data.contract?.holder?.my_type_housing"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
          </div>
        </div>
      </Fieldset>
      <Fieldset  :collapsed="true" legend="Datos Laborales" :toggleable="true" class="p-col-12">
        <div class="p-grid">
          <div class="p-col-12 p-md-3">
            <div class="p-field">
              <label>Nombre de La Empresa</label>
              <input-text type="text" :value="data.contract?.holder?.company_name"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field">
              <label>Teléfono - Extensión</label>
              <input-text type="text" :value="data.contract?.holder?.company_phone+' Ext: '+data.contract?.holder?.company_phone_extension"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field">
              <label>Email de la empresa</label>
              <input-text type="text" :value="data.contract?.holder?.company_email"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
          </div>
          <div class="p-col-12 p-md-3">
            <div class="p-field">
              <label>Dirección de La Empresa</label>
              <input-text type="text" :value="data.contract?.holder?.company_address"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field">
              <label>Tipo de empleado</label>
              <input-text type="text" :value="data.contract?.holder?.my_type_employee"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field">
              <label>Fecha de Ingreso</label>
              <input-text type="text" :value="data.contract?.holder?.date_admission"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
          </div>
          <div class="p-col-12 p-md-3">
            <div class="p-field">
              <label>Cargo</label>
              <input-text type="text" :value="data.contract?.holder?.position"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field">
              <label>Facebook</label>
              <input-text type="text" :value="data.contract?.holder?.facebook"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field">
              <label>Skype</label>
              <input-text type="text" :value="data.contract?.holder?.skype"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
          </div>
          <div class="p-col-12 p-md-3">
            <div class="p-field">
              <label>Ingreso Mensual</label>
              <input-text type="text" :value="data.contract?.holder?.monthly_income"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field">
              <label>Observación</label>
              <textarea rows="5" style="width: 100%" :value="data.contract?.holder?.observation"
                        class="p-inputtext-sm"
                        :disabled="true"/>
            </div>

          </div>
        </div>
      </Fieldset>

      <Fieldset  :collapsed="true" legend="Acuerdos de Pago" :toggleable="true" class="p-col-12" v-if="agreements.length>0">
        <div class="p-grid">
          <div class="p-datatable-wrapper"  style="width: 100%">
            <table role="grid" style="width: 100%">
              <thead class="p-datatable-thead">
              <tr>
                <th class="thC"><span class="p-column-title">Fecha acuerdo pago</span></th>
                <th class="thC"><span class="p-column-title">Observación</span></th>
                <th class="thC"><span class="p-column-title">Fecha de Registro</span></th>
              </tr>
              </thead>
              <tbody class="p-datatable-tbody">
              <tr v-for="(itemx, itx) in agreements" :key="'tr'+itx">
                <td>{{itemx.date_agreement}}</td>
                <td>{{itemx.observation}}</td>
                <td>{{itemx.created_at}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Fieldset>


    </div>


    <ConfirmDialog :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}">></ConfirmDialog>


  </div>
  <div v-else>
    <h5 class="card-header text-center"><b>ATENCIÓN!!</b></h5>
    <div class="card-body">
      <p class="card-title">El acceso a esta opción no se encuentra autorizado</p>
      <Button label="Regresar" class="p-button-danger p-mr-2"
              @click="$router.push('/admin')"/>
    </div>
  </div>
</template>
<script>

import service from '../../service/raiseMoney.service'
import bouncer from "../../../../helpers/bouncer";
import ConfirmDialog from 'primevue/confirmdialog';
import moment from "moment";
import FileSaver from 'file-saver';


export default {
  mixins: [bouncer],
  name: "RaiseMoneyDetailPayment",
  components: { ConfirmDialog,
  },
  data() {
    return {
      agreements:[],
      status: [
      'PARCIALMENTE NO COBRADO',
      'PARCIALMENTE COBRADO'
    ],
      date_payment:null,
      modalDate:false,
      country:null,
      itemSelect:null,
      changeEdit:false,
      dateAssignment:'',
      idAssignment:'',
      loading: false,
      data: {},
      submitted: false,
      employees: [],
      months: [],
      weeks: [],
      subsidiaries: [],
      companies: [],
      organizations: [],
      payments: [],
      plans: [],
      origins: [],
      startDate: null,
      endDate: null,
      dialogStatus:false,
      saldoAnt:0,
      payment_agreement_description:'',
      payment_agreement:null
    }
  },
  mounted() {
    if (this.canAccess('cobranzas_pago_final')) {
      this.$store.dispatch('bread/setItems', [
        {label: 'Matricula para gestión de Cobranza', url: "javascript:void(0);"},
      ]);
      this.getData();
    }
  },
  methods: {
    exportReport(){
      this.loading = true;
      service.downloadData({
       sequential:this.data.sequential
      }).then(x => {
              this.info = x.data;
              var byteCharacters = atob(x.data);
              var byteNumbers = new Array(byteCharacters.length);
              for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              var byteArray = new Uint8Array(byteNumbers);
              var blob = new Blob([byteArray], {type: "application/pdf"});
              this.loading = false;
              FileSaver.saveAs(blob, this.data.sequential+".pdf");
          }).catch((err) => {
        const message = err.response.data;
        let error = this.$utils.formatError(message);
        this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
      }).finally(()=>{
        this.loading = false;
      })
    },
    calculateArrastre(fee,data){
      if(fee.fee===1){
        var sum = data.plan?.total_value - data.payments.reduce(function (total, currentValue) {
          return total + (currentValue.type_payment=='INSCRIPTION' && currentValue.status=='Aprobado'?parseFloat(currentValue.value_paid):0);
        }, 0);
        this.saldoAnt= sum - fee.value_paid;
        return this.saldoAnt
      }else{
        this.saldoAnt=this.saldoAnt - fee.value_paid
        return  this.saldoAnt;
      }

    },

    loadFormDate(data){
      if(this.canAccess('cobranzas_fecha')){
        this.itemSelect=data;
        this.date_payment=data.date_payment
        this.date_payment= moment(this.date_payment, "YYYY-MM-DD").toDate()
        this.modalDate=true;
      }


    },
    async putNewDate(){
      if(this.canAccess('cobranzas_fecha')) {
        this.loading = true
        await service.updateDatePayment(this.itemSelect.id, {
          date_payment: moment(this.date_payment).format("YYYY-MM-DD")
        }).then(() => {
          this.getData()
          this.modalDate = false
          this.loading = false
        }).catch((err) => {
              this.loading = false
              const message = err.response.data;
              let error = this.$utils.formatError(message);
              this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});

            }
        )
      }
    },
    formatMoney(val) {
      return (new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      })).format(val);
    },
    async getData() {
      await service.getPaymentFinishItem(this.$route.params.id, this.$route.params.enrollment,{}).then(x => {
        this.data = x.data.result;
        this.agreements=this.data.processeds?.agreements??[]
      }).finally(() => this.loading = false)
    },
    getTypePayment(type_payment) {
      if(type_payment === 'INSCRIPTION') {
        return 'Inscripción';
      } else if (type_payment === 'FEE') {
        return 'Cuota';
      } else {
        return type_payment;
      }
    }
  }
}
</script>
<style  >
.p-progressbar {
  height: 1px;
}

.p-inputtext {
  width: 100%;
}

.p-autocomplete {
  width: 100%;
}

.p-fieldset {
  margin-top: 15px;
}
.p-field>label{
  font-size: 13px;
}
.p-field {
  padding-top: 0px;
  margin-bottom: 6px;
}

.p-field-checkbox {
  margin-top: 30px;
}

.p-toolbar {
  margin-top: 10px;
  background-color: white;
}

.p-toolbar-group-right {
  width: 100%;
}

.p-calendar {
  height: 30px !important;
}

.p-autocomplete {
  height: 30px !important;
}

tr.p-selectable-row > td {
  padding: 5px!important;
}

.p-datepicker {
  border: none!important;
}
td{
 padding: 8px;
}
.thC{
  text-align: left;
  padding: 0.5rem 0.5rem;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #495057;
  background: #f8f9fa;
  -webkit-transition: -webkit-box-shadow 0.2s;
  transition: -webkit-box-shadow 0.2s;
  transition: box-shadow 0.2s;
  transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
}
</style>
